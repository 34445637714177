@import '../../styles/main.scss';
.revison-container {
  padding-top: 80px;
  @include flex-align-center-vertical();
  align-items: unset;
  flex-direction: column;
  gap: $xxl;
  .projects-header-container {
    margin: 0 !important;
  }
  p {
    margin: 0 !important;
  }
}
.revison-button-group {
  @include flex-align-center-vertical();
  justify-content: flex-end;
  gap: $m;
  align-items: center;
  span {
    align-items: center;
    gap: $xs;
  }
}
.revision-content-container {
  @include flex-align-center-vertical();
  gap: 135px;
  align-items: flex-start;
}
.revision-card {
  div[slot='contents'] {
    @include flex-align-center-vertical();
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .card-action {
    @include flex-align-center-vertical();
    gap: $m;
    align-items: center;
  }
}

.revision-info-container {
  @include flex-align-center-vertical();
  justify-content: space-between;
  align-items: flex-start;
  gap: 135px;
  .revision-info {
    @include flex-align-center-vertical();
    gap: $m;
    flex-direction: column;
    width: 25%;
    align-items: unset;
  }
  .revision-info-item {
    @include flex-align-center-vertical();
    justify-content: space-between;
  }
  .revision-lists {
    width: 75%;
    @include flex-align-center-vertical();
    gap: $m;
    flex-direction: column;
    align-items: flex-start;
    commonux-card {
      .card-container {
        width: 100% !important;
      }
    }
    .list {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      gap: $s;
    }
  }
}
.revision-info-list {
  @include flex-align-center-vertical();
  gap: $m;
  align-items: center;
  padding: $m;
  justify-content: space-between;
  border-bottom: 1px solid $color-grey-20;
  div {
    @include flex-align-center-vertical();
    gap: $s;
  }
  span {
    color: $color-text-black-primary;
    font-size: $font-size-s;
    font-weight: $font-regular;
    line-height: $line-height-s;
  }
  p {
    color: $color-text-black-primary;
    text-align: right;
    font-weight: $font-medium;
    line-height: $line-height-s; /* 17.5px */
  }
}

.revision-creator-eclipse {
  width: $l;
  height: $l;
  border-radius: 50%;
  padding: 9px;
  background-color: $color-grey-20;
  font-size: $sm;
  color: $color-text-black-secondary;
}
.revision-card-text-content {
  @include flex-align-center-vertical();
  flex-direction: column;
  align-items: flex-start;
  gap: $xs;
  h5 {
    color: $color-text-black-primary;
    font-size: $font-size-ml;
    font-weight: $font-medium;
    line-height: $line-height-xl; /* 120% */
  }
  p {
    color: $color-text-black-secondary;
    font-size: $font-size-xs;
    font-weight: $font-regular;
    line-height: $line-height-xs; /* 116.667% */
  }
}
