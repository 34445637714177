@import '../../../../styles/main.scss';

.ProductInfoContainer {
  margin: 0 10px;
  font-family: var(--font-family-abb);
  min-width: 0px;
  width: 100%;
  &.Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 160px;
    @media (min-width: 1025px) and (max-width: 1123px) {
      height: 175px;
    }
    @media (--viewport-medium) {
      height: 190px;
    }
    @media (--viewport-medium) {
      height: 190px;
    }
  }
  &.HeaderIcon {
    height: 55px;
  }
  &.HeaderTitle {
    font-weight: 500;
    font-size: $m;
    line-height: 20px;
    color: #262626;
    margin: 0;
    text-align: center;
  }
  &.HeaderSubtext1 {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    margin: 0;
    text-align: center;
  }
  &.HeaderSubtext2 {
    font-weight: 300;
    font-size: $sm;
    line-height: 15px;
    color: #262626;
    margin: 0;
    text-align: center;
  }
  &.KeyValRow {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding-top: $s;
    padding-bottom: $s;
    min-height: 32px;
  }
  &.KeyValKey {
    margin: 0;
    font-weight: 400;
    font-size: $sm;
    line-height: $m;
    display: flex;
    align-items: center;
    color: #1f1f1f;
    text-align: left;
    img {
      height: $m;
      margin-right: 7px;
    }
  }
  &.KeyValValue {
    margin: 0;
    text-align: right;
    font-weight: 500;
    font-size: $sm;
    line-height: $m;
    color: #1f1f1f;
  }
  .Description {
    font-size: $sm;
    margin: 10px 0px 10px 0px;
  }
  .ProgressBar-Header {
    span:nth-child(1) {
      font-weight: 400;
      font-size: $sm;
      line-height: $m;
      color: #1f1f1f;
      text-align: left;
    }
    span:nth-child(2) {
      text-align: right;
      font-weight: 500;
      font-size: $sm;
      line-height: $m;
      color: #1f1f1f;
    }
  }
  .ProgressBar-Header {
    span:first-child {
      max-width: 65%;
    }
  }
  .ProgressBar-Footer {
    font-weight: 400;
    font-size: $sm;
    line-height: $m;
  }
  .hr {
    margin: $xs 0;
    height: 1px;
    border-top: 1px solid #dbdbdb;
  }
}
.efficiency-losses-data {
  margin-top: $xl;
  padding-top: $xl;
  border-top: 1px solid #bababa;
}
.mt-16 {
  margin-top: $m;
}
