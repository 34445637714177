@import '../../../../styles/main.scss';
.goselect-d-flex {
  @include flex-align-center-vertical();
}
.goselect-flex-gap-8 {
  gap: $s;
}
.goselect-flex-gap-4 {
  gap: $s;
}
.goselect-flex-gap-16 {
  gap: $m;
}
.goselect-flex-gap-12 {
  gap: $sm;
}

.drive-configurator-dual-layout {
  @include flex-align-center-vertical();
  align-items: flex-start;
  gap: $s;
  align-self: stretch;
  .drive-config-input {
    border-radius: $s;
    padding: $ml;
    background: $color-content-background-light-snow;
    @include flex-align-center-vertical();
    flex-direction: column;
    align-items: flex-start;
    gap: $ml;
    flex: 1 0;

    .drive-config-input-card {
      background-color: $color-background-black-tertiary;

      color: $color-brand-white;
      border-radius: $s;
      width: 100%;
      .card-container {
        background-color: inherit !important;
        width: 100%;
      }
    }
    .drive-config-input-card-reconfigure {
      background-color: $background-alternative;
      color: $color-text-black-primary;
      border-radius: $s;
      width: 100%;
      .card-container {
        background-color: inherit !important;
        width: 100%;
      }
    }
    .drive-config-card-content {
      @include flex-align-center-vertical();
      gap: $m;
      align-items: center;
      justify-content: space-between;
      div {
        @include flex-align-center-vertical();
        flex-direction: column;
        align-items: start;
        gap: $xs;
      }
      h5 {
        font-size: $font-size-ml;
        font-weight: $font-medium;
        line-height: $line-height-xl;
      }
      p {
        margin: 0;
        font-size: $font-size-s;
        font-weight: $font-regular;
        line-height: $line-height-m;
      }
      .disable-card-text {
        h5 {
          color: $color-foreground-disable;
          text-align: center;
          font-size: $font-size-ml;
          font-weight: $font-medium;
          line-height: $line-height-xl;
        }
        p {
          color: $color-foreground-disable;
          margin: 0;
          font-size: $font-size-s;
          font-weight: $font-regular;
          line-height: $line-height-m;
        }
      }
    }
  }

  .drive-config-results {
    padding: $ml;
    border-radius: $s;
    background-color: $color-content-background-light-snow;
    @include flex-align-center-vertical();
    flex-direction: column;
    align-items: flex-start;
    gap: $ml;
    flex: 1 0;
    .selection-header {
      @include flex-align-center-vertical();
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }
  }

  .selection-header {
    @include flex-align-center-vertical();
    gap: $s;
    padding-right: $s;

    h5 {
      font-size: $font-size-ml;
      font-weight: $font-medium;
      line-height: $line-height-xl;
    }
  }
  .drive-config-input-footer {
    @include flex-align-center-vertical();
    flex-direction: column;
    align-items: flex-start;
    gap: $m;
    align-self: stretch;
    h6 {
      color: $color-brand-black;
      font-size: $font-size-m;
      font-weight: $font-medium;
      line-height: $line-height-xl; /* 150% */
    }
    p {
      color: $color-brand-black;
      font-size: $sm-14;
      font-weight: $font-regular;
      line-height: $line-height-s; /* 114.286% */
      text-transform: uppercase;
      margin: 0;
    }
    h4 {
      color: $color-text-black-primary;
      font-size: $font-size-ml;
      font-weight: $font-medium;
      line-height: $line-height-xl; /* 150% */
    }
    .drive-config-image-container {
      @include flex-align-center-vertical();
      align-items: flex-start;
      gap: $m;
      align-self: stretch;
      margin-top: 0;
      p {
        text-transform: unset;
        margin: 0;
        font-size: $sm-14;
        font-weight: $font-regular;
        line-height: $line-height-m;
      }
      .desc-container {
        align-self: stretch;
        margin-left: 0;
        width: 50%;
        p {
          color: $color-brand-black;
          font-size: $sm-14;
          font-weight: $font-regular;
          line-height: $line-height-m; /* 142.857% */
        }
      }

      .img-container {
        max-height: 154px;
        max-width: 154px;
        width: 100%;
      }
    }
  }
}

commonux-datagrid-header {
  .label-container {
    color: $color-text-black-secondary !important;
    font-size: $sm-14;
    font-weight: $font-medium;
    line-height: $line-height-m; /* 142.857% */
    text-transform: uppercase;
  }
}

.drive-result-table {
  @include flex-align-center-vertical();
  flex-direction: column;
  align-items: flex-start;
  gap: $s;
  align-self: stretch;
  width: 100%;
  p {
    color: $color-text-black-secondary;
    font-size: $sm-14;
    font-weight: $font-regular;
    line-height: $line-height-s; /* 114.286% */
    text-transform: uppercase;
    margin: 0;
    padding: $s 0;
  }
}

.lvdrivetypecode-modal {
  .backdrop .dialog {
    width: 100%;
  }

  .longtypecode-container {
    @include flex-align-center-vertical();
    flex-direction: column;
    align-items: flex-start;
    gap: $s;
    align-self: stretch;
    .longtypecode-content {
      @extend .longtypecode-container;
      p {
        margin: 0;
        color: $color-text-black-primary;
        font-size: $sm-14;
        font-weight: $font-regular;
        line-height: $line-height-s; /* 114.286% */
      }
    }

    ion-radio-group {
      @include flex-align-center-vertical();
      flex-direction: column;
      gap: $s;
    }
    commonux-radio {
      padding: 6px 0;
    }
    .longtypecode-footer {
      @include flex-align-center-vertical();
      justify-content: flex-end;
      align-items: center;
      gap: $m;
      align-self: stretch;
    }
  }
  .footer {
    display: none !important;
  }
}

.loading-indicator-container {
  @include flex-align-center-vertical();
  align-items: center;
  gap: $s;
  .loader-container {
    width: unset;
  }
  p {
    margin: 0;
    width: 100%;
    color: $color-brand-black;
    font-size: $sm-14;
    font-weight: $font-bold-700;
    line-height: $line-height-m; /* 142.857% */
  }
}

.drive-result-table-header {
  @include flex-align-center-vertical();
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.delete-icon-container {
  padding-right: $s;
}

.drive-config-card-icon-and-text {
  display: flex;
  gap: $ml;
  flex-direction: row !important;
}
.data-grid {
  .icon-menu-container {
    button {
      display: none;
    }
  }
  &:hover {
    .icon-menu-container {
      button {
        display: block;
      }
    }
  }
}
.copy-and-delete-plus-codes-modal {
  padding: 0 24px 24px 24px;

  @include media-mobile() {
    width: 100%;
    padding: 0 12px 12px 12px;
  }
}

.copy-delete-modal-container {
  display: flex;
  flex-direction: column;
  gap: $s;
  align-items: flex-start;
  padding: $s 0;
  width: 485px;
  p {
    margin: 0;
    color: $color-text-black-primary;
    font-size: $font-size-s;
    font-weight: $font-regular;
    line-height: $line-height-s;
  }
  .copied-results-breadcrumb {
    @include flex-align-center-vertical();
    gap: $s;
    div[slot='tag'] {
      font-size: $font-size-m;
      font-weight: $font-regular;
      line-height: $line-height-m;
    }
  }
  .copied-results-breadcrumb-container {
    @include flex-align-center-vertical();

    gap: $s;
    commonux-tag > .selected-tag-element {
      padding: 4px 12px !important;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      .text {
        overflow: hidden;
        color: $color-text-black-primary;
        text-overflow: ellipsis;
        font-size: $font-size-m;
        font-weight: $font-regular;
        line-height: $line-height-m; /* 125% */
      }
    }
  }
  .copy-delete-radio-container {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    gap: $s;
    align-items: flex-start;
    width: inherit;
    div[slot='radio'] {
      padding: 6px;
    }
    .horizontal {
      display: flex;
      gap: 8px;
      flex-direction: column;
    }
    commonux-radio {
      flex-wrap: unset !important;
      gap: $s;
      width: inherit;
      min-width: 480px;
      ion-radio {
        margin: 0 !important;
      }
      .label-desc-section {
        font-size: 12px;
      }
    }
  }
}
.copy-delete-plus-code-footer {
  @include flex-align-center-vertical();
  justify-content: flex-end;
  gap: $s;
  width: 100%;
}

.select-drive-modal-content {
  width: 484px;
  display: flex;
  padding: 12px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.select-drive-modal-content {
  commonux-searchbar {
    width: inherit;
  }
  commonux-datagrid-row {
    opacity: 1 !important;
  }
}
.review-plus-codes-modal {
  & > .backdrop.sc-commonux-dialog .dialog.sc-commonux-dialog {
    width: 100% !important;
    max-width: 854px !important;
    @include media-mobile() {
      width: 100%;
      max-width: 100% !important;
      overflow: auto;
    }
  }

  commonux-datagrid-row {
    opacity: 1 !important;
  }
}

.copy-icon-container {
  button {
    @extend .goselect-flex-gap-12;
  }
}

.drive-result-table-icons {
  @extend .goselect-d-flex;
  align-items: center;
  @extend .goselect-flex-gap-4;
}

.copy-delete-plus-code-dropdown {
  width: fit-content;
  top: 142px;
  right: 70px !important;
}

.indiviudal-copy-delete-plus-code-menu {
  width: fit-content;
  right: 40px !important;
  .line-seperator {
    width: 100%;
    height: 1px;
    background: #bababa;
  }
  .sc-commonux-menu-item-h .label-container.sc-commonux-menu-item {
    text-transform: none !important;
  }
}

.delete-tag-container {
  flex-direction: column;
  align-items: flex-start !important;
  div {
    @extend .goselect-d-flex;
    @extend .goselect-flex-gap-8;
  }
}

.copy-tag-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: $s;
  align-items: center;
}

.review-drive-type-status {
  @include flex-align-center-vertical();
  gap: $s;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  .review-drive-type-code {
    width: 220px;
    flex: 4;
  }
  .review-drivetype-icons {
    flex: 1;
    justify-items: end;
  }
  .is-success {
    fill: #21a67a;
  }
  .not-is-success {
    fill: #ef3a34;
  }
}
.review-delete-plus-code-text {
  @include flex-align-center-vertical();
  gap: $s;
  span {
    color: $color-text-black-secondary;
    font-size: $font-size-xs;
    font-weight: $font-regular;
    line-height: $line-height-xs; /* 116.667% */
    flex: 1;
  }
}
.select-drive-modal-content {
  commonux-datagrid commonux-datagrid-row:first-child {
    position: static !important;
  }
  .sc-commonux-tooltip-h {
    position: static !important;
  }
  .sc-commonux-tooltip-h > .tooltip-container.left.sc-commonux-tooltip {
    width: 100% !important;
    height: fit-content !important;
    right: 10% !important;
  }
}
