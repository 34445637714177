@import '../../styles/_variables.scss';
@import '../../styles/_utils.scss';
@import '../../styles/main.scss';

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  min-height: 100vh;
  width: 100%;

  @include responsive-media-query($media-lg, $media-xl) {
    width: 100%;
  }

  commonux-input {
    .sc-commonux-select-container-h .text-input.sc-commonux-select-container {
      width: max-content !important;
    }
  }

  .display-block {
    display: block !important;
  }

  & > div > .header {
    position: sticky;
    top: 0;
    z-index: 23;

    .header-conatiner {
      background-color: var(--background-primary);
    }

    .lang-selection {
      display: flex;
      gap: $xs;
      font-weight: $font-bold;
    }

    .globalControls-container {
      display: flex;
      gap: $m;

      @include media-mobile() {
        margin-right: $s;
      }

      .user-profile {
        display: block;
        height: auto;

        .user-profile-authorized {
          button {
            padding: $xs !important;
          }
        }

        button {
          padding: $xs $sm;
          @include media-mobile() {
            padding: $xs;
          }
        }

        .login-button {
          .tertiary {
            .text {
              @include media-mobile() {
                display: none;
              }
            }
          }
        }
      }

      commonux-menu {
        width: max-content;
        float: right;
        position: absolute;
        margin-right: $ml;
      }
    }
    .globalControls-container-authorization {
      margin-right: $s;
      @include media-mobile() {
        margin-right: 0;
      }
    }
  }
  .globalControls-slot-container {
    @include media-mobile() {
      margin-right: 0;
    }
  }

  & > .option-card > .card-container {
    padding: $l;
  }

  .project-status-container {
    display: flex;
    padding-left: $sm;
    color: $color-text-black-secondary;
    font-size: $font-size-s;
    font-weight: $font-regular;

    .primary-black {
      padding: $xs $sm !important;
    }
  }

  .page-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 92px;
    align-self: stretch;
    padding: $xxl 0 $ml;
    & > :nth-child(1) {
      @include media-mobile() {
        order: 1;
      }
    }

    @include media-mobile() {
      flex-direction: column;
      align-items: center;
      gap: $ml;
      margin: 0;
      padding-top: 0;
    }
    @include media-tablet() {
      flex-direction: row;
      align-items: center;
      gap: $sm;
      padding-top: 0;
    }
    @include media-desktop() {
      gap: 54px;
      padding-top: $ml;
    }
  }
  .copyright {
    font-size: $font-size-xs;
    color: $color-brand-black;
    font-weight: $font-regular;
    line-height: $line-height-xs;
  }
  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $l;
    flex: 1 0 0;
    cursor: pointer;
    section {
      display: flex;
      padding: 0;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: $m;

      @include media-mobile() {
        padding: 0;
      }
    }

    p {
      color: $color-text-black-secondary;
      margin: 0;
      padding: 0 $m;
      @include media-desktop() {
        font-size: $font-size-s;
        line-height: $line-height-s;
      }
      @include media-tablet() {
        font-size: $font-size-s;
        line-height: $line-height-s;
      }
      @include media-mobile() {
        padding: 0;
        text-decoration: underline;
        text-underline-position: under;
      }
    }
    @include media-mobile() {
      flex-direction: column;
      gap: $xs;
      align-items: center;
      order: 0;
      font-size: $font-size-s;
      line-height: normal;
      p {
        margin: 0;
      }
    }
    @include media-tablet() {
      flex-direction: row;
      gap: $xs;
      font-size: $font-size-s;
    }
    @include media-desktop() {
      gap: $sm;
    }
  }

  .footer-link {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: $m;
    p {
      color: $color-text-black-secondary;
      text-align: center;
      margin: 0;
      font-size: $font-size-m;
      font-weight: $font-regular;
      line-height: $line-height-xl;
      cursor: pointer;
      @include media-desktop() {
        font-size: $font-size-s;
        line-height: $line-height-s;
      }
      @include media-tablet() {
        font-size: $font-size-s;
        line-height: $line-height-s;
      }
      @include media-mobile() {
        font-size: $font-size-s;
        text-decoration: underline;
        text-underline-position: under;
      }
    }
    @include media-mobile() {
      padding: 0;
    }
  }

  .footer-section-container {
    margin-top: 84px;
    @include media-mobile() {
      margin-top: $l;
    }
    .footer-container {
      display: flex;
      flex-direction: column;
      padding: $ml;
      gap: $ml;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      @include media-mobile() {
        background-color: $color-brand-white;
      }
      .footer-header-container {
        width: 100%;
        border-top: $border-width-s solid $color-footer-border;

        @include media-mobile() {
          border: 0;
          background-color: $color-white-primary;
        }
      }
    }
  }
  @include media-tablet() {
    margin: 0;
  }
  @include media-mobile() {
    margin: 0;
  }
}

.brand-name {
  color: $color-text-black-secondary;
  font-size: $m;
}

.header-brandplate {
  cursor: auto;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  .navigation-icon {
    fill: $color-text-black-secondary;
  }
}

.breadcrumb-last-item {
  font-size: $m;
  font-weight: $font-medium;
  line-height: $ml;
}

.primaryNav-slot-container {
  display: flex !important;
  margin: 0 !important;
}

*:not(.selection-modal .dialog .content):not(
    .manual-selection-modal .dialog .content
  ):not(.left-pane-container)::-webkit-scrollbar {
  display: none;
}

.dual-section-container {
  display: flex;
  gap: $s;
}

.left-side-container {
  background-color: $color-white-primary;
  border-radius: $s;
  padding: $ml;
  width: 60%;
  position: relative;

  @include media-xl() {
    width: 50%;
  }

  @include media-mobile() {
    flex-direction: column;
    width: 100%;
    padding: $m;
  }
}

.right-side-container {
  width: 40%;
  background-color: $color-white-primary;

  border-radius: $s;
  padding: $ml;
  height: -moz-fit-content;
  height: fit-content;

  position: relative;

  @include media-xl() {
    width: 50%;
  }

  @include media-mobile() {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: $m;
  }
}

.section-content-header-text {
  margin-top: $ml;
  margin-left: $m;
  font-size: $font-size-ml;
  font-weight: $font-medium;
}

.sidebar-collapsed-left-side-container {
  background-color: $color-white-primary;
  border-radius: $s;
  width: 50%;
  padding: $ml;
}

.sidebar-collapsed-right-side-container {
  margin-right: $m;
  width: 50%;
  background-color: $color-white-primary;
  margin-left: $s;
  border-radius: $s;
  padding: $m;
  height: -moz-fit-content;
  height: fit-content;
}
@include media-tablet() {
  commonux-footer .footer-container [slot='footerTop'] {
    display: flex;
    flex-direction: row;
    margin-top: $xl;
  }
}

.feedback-button {
  z-index: 20;
  border-radius: $xs;
}

.feedback-modal {
  .dialog {
    .content {
      @include custom-scrollbar();
    }
  }
}

.feedback-modal-button {
  width: 100%;
  margin: $s 0;
}
.feedback-modal-button-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: $ml;
  font-size: $font-size-s;
  font-weight: $font-bold-700;
  text-align: center;
  text-align: -webkit-center;
  align-items: center;
  margin-top: $m;

  commonux-card {
    width: 160px;
  }
}

.feedback-stars {
  margin: $m $ml 0px $ml;

  .star-svg {
    margin-right: 34px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.feedback-question-subtext {
  display: flex;
  margin: 0 $m;
  justify-content: space-between;
  color: $color-black-primary;
  font-size: $font-size-xs;
  font-weight: $font-regular;
  line-height: $m;
}

.feedback-submitted-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: $m;
  margin: $ml 0;
  align-items: center;
  text-align: center;

  .feedback-modal-submit-icon {
    zoom: 2.5;

    svg {
      fill: $color-status-success !important;
    }
  }

  .submit-success-title {
    color: $color-text-black-primary;
    font-size: $font-size-ml;
    font-weight: $font-medium;
    line-height: $ml;
  }

  .submit-success-subtext {
    color: $color-text-black-primary;
    font-size: $font-size-s;
    font-weight: $font-regular;
    line-height: $font-size-ml;
  }
}

.feedback-modal-question-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: $m;
  font-size: $font-size-s;
  font-weight: $font-bold-700;
  margin-top: $m;
}

.feedback-modal-text {
  font-size: $font-size-s;
  font-weight: $font-regular;
  line-height: $line-height-m;
  margin-bottom: $m;
}

.screenshot-subtext {
  margin-top: $xs;
  margin-bottom: $m;
  color: $color-grey-50;
  font-size: $font-size-xxs;
  font-weight: $font-regular;
  line-height: $line-height-xxs;
}

.attachment-text-container {
  display: flex;
  flex-direction: column;
  margin-top: $s;
  gap: $m;
  color: $color-grey-50;
  font-size: $font-size-xxs;
  font-weight: $font-regular;
  line-height: $line-height-xxs;
}

.legal-menu {
  width: 190px !important;
  margin-top: $xs !important;
}

.vandlpagecontainer {
  margin-top: 48px;

  a {
    background-color: $color-grey-20;
    padding: $s $m;
    margin: 0;
    font-size: $font-size-s;
    font-weight: $font-medium;
    line-height: $line-height-m;
    color: $color-black-primary;
  }
  .selected {
    background-color: $color-white-primary !important;
    border-top: $border-width-m solid $color-brand-black;
  }
}

.info-tabs-container {
  background-color: $color-white-primary;
  padding: 64px;
  @include media-mobile() {
    padding: $l $s;
  }
  @include media-tablet() {
    padding: $xl $m;
  }
  .version-container {
    display: flex;
    flex-direction: column;
  }

  h1 {
    display: block;
    font-weight: $font-bold-700;
    font-size: 28px;
    margin: $ml 0;
  }
  p {
    line-height: $line-height-s;
    margin: $xs 0;
    font-size: $font-size-s;
  }
  h3 {
    font-size: $font-size-ml;
    margin: $s 0;
  }
  h2 {
    font-size: $font-size-ml;
    font-weight: $font-bold;
    line-height: normal;
  }
}

.legal-container {
  .legal-body {
    margin-top: $ml;
    p {
      line-height: $line-height-s;
      margin: $m 0;
      font-size: $font-size-s;
    }
  }
}

.legal-menu .menu-container commonux-menu-item .menu-item-container {
  height: auto !important;
}

commonux-brandplate .brand-plate {
  cursor: context-menu !important;
}

.workspace-switch-button-container {
  padding-left: $s;
  padding-right: $s;
  display: flex;
}

.drive-motor-button {
  max-width: max-content;
}

.sc-commonux-label-h
  .top-label.sc-commonux-label
  .label-limit.sc-commonux-label {
  min-width: max-content !important;
}

.sc-commonux-text-area-h .wrapper.sc-commonux-text-area {
  padding-top: 0px !important;
}
