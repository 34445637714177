@import '../../../../styles/main.scss';

.brand-logo {
  text-align: right;

  img {
    width: 136px;
    height: 48px;
  }
}

.brand-color-bar {
  background-color: $color-brand-red;
  font-style: normal;
  font-weight: $font-bold-700;
  margin: 80px 0 $m;
  height: 5px;
  width: 40px;
}

.report-header {
  color: $color-grey-90;
  font-size: $font-size-xs;
  font-style: normal;
  font-weight: $font-regular;
  line-height: $line-height-s;
  text-transform: uppercase;
}

.report-project {
  color: $color-grey-90;
  font-size: $font-size-xl;
  font-style: normal;
  font-weight: 700;
  line-height: $line-height-xxl;
}

.report-project-list {
  color: $color-grey-90;
  font-size: $font-size-l;
  font-style: normal;
  font-weight: $font-light;
  line-height: 32px;
}

.report-section {
  background-color: $color-white-primary;
  border-radius: $s;
  padding: calc($l + $xs);
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 85vh;
}

.report-form {
  width: 280px;
  background-color: $color-white-primary;
  border-radius: $s;
  padding: $ml;
}

.report-form-heading,
.project-information-header {
  color: $color-brand-black;
  font-size: $font-size-m;
  font-style: normal;
  font-weight: $font-medium;
  line-height: $font-size-l;
}

.project-information-header {
  border-top: 2px solid $color-grey-90;
  border-bottom: 1px solid $color-grey-90;
  padding: $s;
  margin-top: $m;
  color: $color-text-black-primary;
  font-size: $font-size-xxs;
  font-weight: $font-medium;
  line-height: $line-height-xxs;
}

.commonux-dialog > .backdrop .dialog {
  max-width: unset;
}

.project-information {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  min-width: 100%;
}

.project-description-header {
  color: $color-brand-black;
  font-size: $font-size-xxs;
  font-style: normal;
  font-weight: $font-medium;
  line-height: $line-height-xxs;
  margin-top: $xs;
}

.project-description-content {
  color: $color-brand-black;
  font-size: $font-size-xxs;
  font-style: normal;
  font-weight: $font-light;
  line-height: $line-height-xxs;
}

.report-technical-data-container {
  display: flex;
  flex-direction: row;
  gap: 32px;

  span {
    color: $color-text-black-primary;
    font-size: $m;
    font-style: normal;
    font-weight: 700;
    line-height: $ml;
  }

  div {
    flex: 50%;
    gap: 32px;
  }
}

.segment-container {
  margin: $sm 0;
  .segment-header {
    color: $color-text-black-primary;
    font-size: $font-size-m;
    font-weight: $font-medium;
    line-height: $line-height-xl;
  }

  .segment-subheader {
    color: $color-text-black-primary;
    font-size: $font-size-s;
    font-style: normal;
    font-weight: 700;
    line-height: $line-height-m; /* 150% */
    margin-top: $sm;
  }

  .segment-type-header {
    color: $color-text-black-primary;
    font-size: $font-size-xs;
    font-style: normal;
    font-weight: $font-light;
    line-height: $line-height-xs; /* 120% */
  }

  .segment-type-content {
    color: $color-text-black-primary;
    font-size: $font-size-xs;
    font-weight: $font-regular;
    line-height: $line-height-s; /* 142.857% */
  }

  .segment-contentheader {
    color: $color-text-black-primary;
    font-size: $font-size-xs;
    font-weight: 700;
    line-height: $line-height-m; /* 142.857% */
    margin-top: $sm;
  }

  .efficiency-report-header {
    margin-bottom: 16px;
  }

  .segment-type-sub-container {
    margin-top: $s;
    padding-right: $s;
    margin-bottom: $s;
    width: 50%;
  }

  .product-label-title {
    color: $color-text-black-primary;
    font-size: $font-size-xxs;
    font-weight: $font-medium;
    line-height: $line-height-xxs;
  }

  .segment-type-container {
    display: flex;
    display: -webkit-flex;
  }

  .segment-type-description {
    color: $color-text-black-primary;
    font-size: $font-size-xxs;
    font-weight: $font-regular;
    line-height: $line-height-xxs; /* 116.667% */
    margin-top: $s;
  }

  .project-data-container {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    flex-direction: row;
    gap: 16px;

    .project-data-content-container {
      width: 50%;

      .segment-title {
        div {
          width: 100%;
        }
      }

      .segment-content {
        border-bottom: 1px solid $color-grey-20;
        display: flex;
        flex-direction: row;

        div {
          width: 50%;
        }
      }
    }
  }

  .drives-container {
    .segment-content-header {
      div {
        width: 50% !important;
      }
    }

    .segment-content {
      div {
        &:last-child {
          display: flex;
          border-left: 1px solid $color-grey-20;
          div {
            min-width: 50% !important;
            width: 50%;
            padding: 0;
            &:last-child {
              padding-left: $s;
              border-left: 1px solid $color-grey-20;
            }
          }
        }
      }
    }
  }

  .dimension-contents-container {
    .segment-title {
      div {
        width: 25%;
        min-width: 25%;
      }
    }

    .segment-catalog-content {
      div {
        width: 50% !important;
        min-width: 50% !important;
      }
    }

    .segment-content-header {
      border-bottom: 1px solid $color-grey-20;
      background: var(--Black-opacity-2, rgba(0, 0, 0, 0.04));
      div {
        color: $color-text-black-primary;
        font-size: $sm;

        font-weight: $font-medium !important;
        line-height: $line-height-s; /* 133.333% */
      }
    }

    .segment-content {
      div {
        width: 25%;
        min-width: 25%;
        color: $color-text-black-primary;
        font-size: 9px;
        font-weight: $font-regular;
        line-height: 10px; /* 133.333% */
      }
    }
  }

  .segment-title {
    border-top: 2px solid $color-grey-90;
    border-bottom: 1px solid $color-grey-90;
    margin-top: $s;
    display: flex;
    flex-direction: row;

    div {
      color: $color-text-black-primary;
      font-size: $font-size-xxs;
      font-style: normal;
      font-weight: $font-bold-700;
      line-height: $line-height-xxs;
      width: 25%;
      padding: $s;

      &:first-child {
        width: 6%;
      }
    }
  }

  .segment-content {
    border-bottom: 1px solid $color-grey-20;
    display: flex;
    flex-direction: row;

    div {
      color: $color-text-black-primary;
      font-size: 9px;
      font-weight: $font-regular;
      line-height: $line-height-xxs;
      width: 25%;
      padding: $s;

      &:first-child {
        width: 6%;
      }
    }
  }
}

.project-information-col {
  width: 50%;
}

.project-information-col div,
.project-information-col-right div {
  border-bottom: 1px solid $color-grey-20;
  padding: $xs $s;
  color: $color-text-black-primary;
  font-size: $font-size-xxs;
  font-style: normal;
  font-weight: $font-light;
  line-height: $line-height-xxs; /* 133.333% */
  text-transform: uppercase;
}

.project-information-col-right div {
  border-left: 1px solid $color-grey-20;
}

.project-information-col p,
.project-information-col-right p {
  color: $color-text-black-primary;
  font-size: $font-size-xxs;
  font-weight: $font-regular;
  line-height: $line-height-xxs; /* 133.333% */
  margin: 0;
  text-transform: initial;
}

.project-information-col-right {
  width: 50%;
}

.user-comments {
  margin-top: $ml;
  font-size: $font-size-xxs;
  font-weight: $font-medium;
  line-height: $line-height-xxs;

  .user-comments-title {
    color: $color-text-black-primary;
    font-weight: 700;
  }
  .user-comments-content {
    color: var(--text-black-secondary, #696969);
    font-weight: $font-regular;
  }
}

.report-ecodesign-message {
  display: flex;
  flex-direction: row;
  margin-top: $ml;
  color: var(--font-light-secondary, #696969);

  commonux-icon {
    fill: var(--font-light-secondary, #696969);
  }
}

.report-page-loss-container {
  margin-top: $ml;

  .report-page-loss-header {
    color: $color-text-black-primary;
    font-size: $ml;
    font-style: normal;
    font-weight: 700;
    line-height: $ml;
  }

  .report-page-loss-subheader {
    margin: $m 0 $s;
    color: $color-text-black-primary;
    font-size: $m;
    font-style: normal;
    font-weight: 700;
    line-height: $ml;
  }

  .report-page-loss-content {
    color: $color-text-black-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-line;

    ol {
      list-style: decimal;
    }
    sub {
      vertical-align: sub;
      font-size: 70%;
      line-height: 0;
    }
  }
}

.report-efficiency-container {
  // padding: $ml 0 $l;
  margin: $ml 0 0;

  .D3 {
    padding-left: 0 !important;
  }
}
.efficiency-Report-heading {
  margin: 0;
  padding-bottom: $ml;
  font-size: $font-size-s;
  font-weight: 700;
  line-height: $line-height-m;
}
.udp-loss-table-heading {
  @extend .efficiency-Report-heading;
  padding-bottom: $sm;
}

.udppoints-losses-imp-notes {
  color: $color-text-black-secondary;
  font-size: $sm;
  font-weight: $font-regular;
  margin: $s 0;
}
#pdf-section {
  .label-content {
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
  }
  .desc-container {
    font-size: $font-size-xxs;
    line-height: $line-height-xxs;
    font-weight: $font-regular;
  }
}
.motot-data-catalouge {
  width: 100% !important;
}
//for report modal
.plus-code-title {
  border-top: 2px solid $color-grey-90;
  border-bottom: 1px solid $color-grey-90;
  margin-top: $s;
  display: flex;
  flex-direction: row;
  div {
    width: 25%;
    min-width: 25%;
    color: $color-text-black-primary;
    font-size: 9px;
    font-weight: $font-bold-700;
    line-height: 10px; /* 133.333% */
    padding: $s;
  }
  .column-30 {
    width: 25% !important;
  }

  .column-70 {
    width: 70% !important;
  }
}

.plus-code-content {
  display: flex;
  border-bottom: 1px solid $color-grey-20;
  div {
    width: 25%;
    min-width: 25%;
    color: $color-text-black-primary;
    font-size: 9px;
    font-weight: $font-regular;
    line-height: 10px; /* 133.333% */
    padding: $s;
  }
  .column-30 {
    width: 25% !important;
  }

  .column-70 {
    width: 70% !important;
  }
}
.plus-code-header {
  color: $color-text-black-primary;
  font-size: $font-size-m;
  font-weight: $font-medium;
  line-height: $line-height-xl;
}
